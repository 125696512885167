import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import BreadcrumbSection from '../elements/BreadcrumbSection';

const FAQ = () => {
  // 取分类 和 FAQ数据
  const { allStrapiFaq, allStrapiFaqType } = useStaticQuery(graphql`
    query {
      allStrapiFaq {
        edges {
          node {
            id
            Answer {
              data {
                Answer
              }
            }
            Question
            Type {
              id
            }
          }
        }
      }
      allStrapiFaqType {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `);

  const [activeId, setActiveId] = useState(allStrapiFaqType?.edges?.[0]?.node?.id);

  const handleChange = (newActiveId: any) => {
    setActiveId(newActiveId);
  };

  return (
    <Layout>
      <Seo
        title="FAQ | banco Frequently Asked Questions"
        description="View our comprehensive list of the most common frequently asked questions. Chat or email us directly."
      />
      <main className="main-wrapper">
        <HeaderCommon />

        <BreadcrumbSection title="FAQ" />

        {/* FAQ */}
        <div className="section faq-wrap">
          <div className="container">
            {/* TAB栏 */}
            <ul className="nav nav-tabs" role="tablist">
              {allStrapiFaqType.edges.map(({ node }: any) => (
                <li className="nav-item" key={node.id} role="presentation">
                  <button
                    className={`nav-link${activeId === node.id ? ' active' : ''}`}
                    type="button"
                    role="tab"
                    aria-selected="true"
                    onClick={() => handleChange(node.id)}
                  >
                    {node.name}
                  </button>
                </li>
              ))}
            </ul>

            <div className="row">
              {allStrapiFaq.edges
                .filter(({ node }: any) => node.Type.id && node.Type.id === activeId)
                .map(({ node }: any) => (
                  <div>
                    <h3>Q: {node.Question}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: node.Answer.data.Answer.replace(
                          /banco-website-static.s3.ap-southeast-1.amazonaws.com/g,
                          'website-static.banco.com.sg',
                        ),
                      }}
                    />
                  </div>
                ))}
            </div>

            <div className="row row-8 justify-content-md-center">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Link className="banco-btn btn-fill-primary btn-fluid" to="/contact">
                  Speak with our team
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </Layout>
  );
};

export default FAQ;
